
export default {
  components: {
    HomepageBlogCard: () =>
      import('@/containers/theme/HomepageBlogs/HomepageBlogCard.vue'),
  },
  data() {
    return {
      blogPosts: [],
    }
  },
  mounted() {
    this.$axios.$get('blogs/home').then((response) => {
      this.blogPosts = response.data.map((post) => ({
        id: post.id,
        title: post.title,
        description: post.description,
        alt: post.title,
        image: post.media,
        to: '/blogs/ytc-life/' + post.slug,
        date: post.published_at,
      }))
    })
  },
}
